const programsList = [
    {
        title: `A Noisy Class`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/A%20Noisy%20Class.cpp`,
        id: "a_noisy_class",
    },
    {
        title: `CCC '00 S2 - Babbling Brooks`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/CCC%20'00%20S2%20-%20Babbling%20Brooks.cpp`,
        id: "ccc_00_s2_babbling_brooks",
    },
    {
        title: `CCC '00 S4 - Golf`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/CCC%20'00%20S4%20-%20Golf.cpp`,
        id: "ccc_00_s04_golf",
    },
    {
        title: `CCC '03 S3 - Floor Plan`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/CCC%20'03%20S3%20-%20Floor%20Plan.cpp`,
        id: "ccc_03_s3_floor_plan",
    },
    {
        title: `CCC '05 J5 - Bananas`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/CCC%20'05%20J5%20-%20Bananas.cpp`,
        id: "ccc_05_j5_bananas",
    },
    {
        title: `CCC '07 J4 - Anagram Checker`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/CCC%20'07%20J4%20-%20Anagram%20Checker.cpp`,
        id: "ccc_07_j4_anagram_checker",
    },
    {
        title: `CCC '07 J5 - Keep on Truckin'`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/CCC%20'07%20J5%20-%20Keep%20on%20Truckin'.cpp`,
        id: "ccc_07_j5_keep_on_truckin",
    },
    {
        title: `CCC '07 S4 - Waterpark`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/CCC%20'07%20S4%20-%20Waterpark.cpp`,
        id: "ccc_07_s4_waterpark",
    },
    {
        title: `CCC '09 S3 - Degrees Of Separation`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/CCC%20'09%20S3%20-%20Degrees%20Of%20Separation.cpp`,
        id: "ccc_09_s3_degrees_of_separation",
    },
    {
        title: `CCC '10 J5 - Knight Hop`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/CCC%20'10%20J5%20-%20Knight%20Hop.cpp`,
        id: "ccc_10_j5_knight_hop",
    },
    {
        title: `CCC '11 J4 - Boring Business`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/CCC%20'11%20J4%20-%20Boring%20Business.cpp`,
        id: "ccc_11_j4_boring_business",
    },
    {
        title: `CCC '11 J5 - Unfriend`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/CCC%20'11%20J5%20-%20Unfriend.cpp`,
        id: "ccc_11_j5_unfriend",
    },
    {
        title: `CCC '12 S5 - Mouse Journey`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/CCC%20'12%20S5%20-%20Mouse%20Journey.cpp`,
        id: "ccc_12_s5_mouse_journey",
    },
    {
        title: `CCC '13 S2 - Bridge Transport`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/CCC%20'13%20S2%20-%20Bridge%20Transport.cpp`,
        id: "ccc_13_s2_bridge_transport",
    },
    {
        title: `CCC '13 S4 - Who is Taller?`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/CCC%20'13%20S4%20-%20Who%20is%20Taller%3F`,
        id: "ccc_13_s4_who_is_taller",
    },
    {
        title: `CCC '14 S2 - Assigning Partners`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/CCC%20'14%20S2%20-%20Assigning%20Partners.cpp`,
        id: "ccc_14_s2_assigning_partners",
    },
    {
        title: `CCC '14 S3 - The Geneva Confection`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/CCC%20'14%20S3%20-%20The%20Geneva%20Confection.cpp`,
        id: "ccc_14_s3_the_geneva_confection",
    },
    {
        title: `CCC '15 S3 - Gates`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/CCC%20'15%20S3%20-%20Gates.cpp`,
        id: "ccc_15_s3_gates",
    },
    {
        title: `CCC '16 S2 - Tandem Bicycle`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/CCC%20'16%20S2%20-%20Tandem%20Bicycle.cpp`,
        id: "ccc_16_s2_tandem_bicycle",
    },
    {
        title: `CCC '18 J5 - Choose your own path`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/CCC%20'18%20J5%20-%20Choose%20your%20own%20path.cpp`,
        id: "ccc_18_j5_choose_your_own_path",
    },
    {
        title: `CCC '20 S2 - Escape Room`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/CCC%20'20%20S2%20-%20Escape%20Room.cpp`,
        id: "ccc_20_s2_escape_room",
    },
    {
        title: `CCC '96 S4 - When in Rome`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/CCC%20'96%20S4%20-%20When%20in%20Rome.cpp`,
        id: "ccc_96_s4_when_in_rome",
    },
    {
        title: `CCCHK '15 J3 - Queens can't attack me!`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/CCCHK%20'15%20J3%20-%20Queens%20can't%20attack%20me!.cpp`,
        id: "ccchk_15_j3_queens_cant_attack_me",
    },
    {
        title: `CCO '10 P4 - Computer Purchase Return`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/CCO%20'10%20P4%20-%20Computer%20Purchase%20Return.cpp`,
        id: "cco_10_p4_computer_purchase_return",
    },
    {
        title: `Coin Change`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/Coin%20Change.cpp`,
        id: "coin_change",
    },
    {
        title: `DMOPC '16 Contest 1 P3 - Shoe Shopping`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/DMOPC%20'16%20Contest%201%20P3%20-%20Shoe%20Shopping.cpp`,
        id: "dmopc_16_contest_1_p3_shoe_shopping",
    },
    {
        title: `DMPG '15 B4 - Maximum Product`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/DMPG%20'15%20B4%20-%20Maximum%20Product.cpp`,
        id: "dmpg_15_b4_maximum_product",
    },
    {
        title: `Disjoint Set Test`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/Disjoint%20Set%20Test.cpp`,
        id: "disjoint_set_test",
    },
    {
        title: `Facebook Hacker Cup '15 Round 1 P1 - Homework`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/Facebook%20Hacker%20Cup%20'15%20Round%201%20P1%20-%20Homework.cpp`,
        id: "facebook_hacker_cup_15_round_1_p1_homework",
    },
    {
        title: `Fibonacci Sequence`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/Fibonacci%20Sequence.cpp`,
        id: "fibonacci_sequence",
    },
    {
        title: `IOI '09 P3 - POI`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/IOI%20'09%20P3%20-%20POI.cpp`,
        id: "ioi_09_p3_poi",
    },
    {
        title: `IOI '09 P5 - Garage`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/IOI%20'09%20P5%20-%20Garage.cpp`,
        id: "ioi_09_p5_garage",
    },
    {
        title: `IOI '94 P1 - The Triangle`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/IOI%20'94%20P1%20-%20The%20Triangle.cpp`,
        id: "ioi_94_p1_the_triangle",
    },
    {
        title: `Longest Common Subsequence`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/Longest%20Common%20Subsequence.cpp`,
        id: "longest_common_subsequence",
    },
    {
        title: `Longest Increasing Subsequence`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/Longest%20Increasing%20Subsequence.cpp`,
        id: "longest_increasing_subsequence",
    },
    {
        title: `Postfix Notation`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/Postfix%20Notation.cpp`,
        id: "postfix_notation",
    },
    {
        title: `Sudoku Challenge`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/Sudoku%20Challenge.cpp`,
        id: "sudoku_challenge",
    },
    {
        title: `TLE '17 Contest 3 P3 - Fax's Thanksgiving Dish`,
        link: `https://raw.githubusercontent.com/AyoubElleuch/Competitive-Programming-Solutions/main/TLE%20'17%20Contest%203%20P3%20-%20Fax's%20Thanksgiving%20Dish.cpp`,
        id: "tle_17_contest_3_p3_faxs_thanksgiving_dish",
    },
];


export default programsList;