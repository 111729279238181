import React, { useEffect } from "react";
import processData from "./Treemap/TreemapHelper";
import "./Treemap/Treemap.css";

const Treemap = () => {
  useEffect(() => {
    fetch(
      "https://cdn.freecodecamp.org/testable-projects-fcc/data/tree_map/video-game-sales-data.json"
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error while fetching data");
        }
        return response.json();
      })
      .then((data) => {
        processData(data);
      })
      .catch((error) => {
        console.error(error);
      });

    return () => {};
  }, []);

  return (
    <div id="treemap-wrapper">
      <div>
        <h1 id="treemap-title">Video Game Sales</h1>
        <h2 id="treemap-description">
          Top 100 Most Sold Video Games Grouped by Platform
        </h2>
      </div>
      <div className="treemap-visHolder"></div>
      <div id="treemap-legend">
        <div className="treemap-legend-item">
          <svg
            className="treemap-square-svg"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect className="treemap-legend-item" width="20" height="20" fill="#009688" />
          </svg>
          <span>Wii</span>
        </div>
        <div className="treemap-legend-item">
          <svg
            className="treemap-square-svg"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect className="treemap-legend-item" width="20" height="20" fill="#FF5722" />
          </svg>
          <span>DS</span>
        </div>
        <div className="treemap-legend-item">
          <svg
            className="treemap-square-svg"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect className="treemap-legend-item" width="20" height="20" fill="#3F51B5" />
          </svg>
          <span>X360</span>
        </div>
        <div className="treemap-legend-item">
          <svg
            className="treemap-square-svg"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect className="treemap-legend-item" width="20" height="20" fill="#8BC34A" />
          </svg>
          <span>GB</span>
        </div>
        <div className="treemap-legend-item">
          <svg
            className="treemap-square-svg"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect className="treemap-legend-item" width="20" height="20" fill="#FFC107" />
          </svg>
          <span>PS3</span>
        </div>
        <div className="treemap-legend-item">
          <svg
            className="treemap-square-svg"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect className="treemap-legend-item" width="20" height="20" fill="#673AB7" />
          </svg>
          <span>NES</span>
        </div>
        <div className="treemap-legend-item">
          <svg
            className="treemap-square-svg"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect className="treemap-legend-item" width="20" height="20" fill="#F44336" />
          </svg>
          <span>PS2</span>
        </div>
        <div className="treemap-legend-item">
          <svg
            className="treemap-square-svg"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect className="treemap-legend-item" width="20" height="20" fill="#2196F3" />
          </svg>
          <span>3DS</span>
        </div>
        <div className="treemap-legend-item">
          <svg
            className="treemap-square-svg"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect className="treemap-legend-item" width="20" height="20" fill="#9C27B0" />
          </svg>
          <span>PS4</span>
        </div>
        <div className="treemap-legend-item">
          <svg
            className="treemap-square-svg"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect className="treemap-legend-item" width="20" height="20" fill="#FF9800" />
          </svg>
          <span>SNES</span>
        </div>
        <div className="treemap-legend-item">
          <svg
            className="treemap-square-svg"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect className="treemap-legend-item" width="20" height="20" fill="#795548" />
          </svg>
          <span>PS</span>
        </div>
        <div className="treemap-legend-item">
          <svg
            className="treemap-square-svg"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect className="treemap-legend-item" width="20" height="20" fill="#CDDC39" />
          </svg>
          <span>N64</span>
        </div>
        <div className="treemap-legend-item">
          <svg
            className="treemap-square-svg"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect className="treemap-legend-item" width="20" height="20" fill="#FFEB3B" />
          </svg>
          <span>GBA</span>
        </div>
        <div className="treemap-legend-item">
          <svg
            className="treemap-square-svg"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect className="treemap-legend-item" width="20" height="20" fill="#607D8B" />
          </svg>
          <span>XB</span>
        </div>
        <div className="treemap-legend-item">
          <svg
            className="treemap-square-svg"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect className="treemap-legend-item" width="20" height="20" fill="#4CAF50" />
          </svg>
          <span>PC</span>
        </div>
        <div className="treemap-legend-item">
          <svg
            className="treemap-square-svg"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect className="treemap-legend-item" width="20" height="20" fill="#E91E63" />
          </svg>
          <span>2600</span>
        </div>
        <div className="treemap-legend-item">
          <svg
            className="treemap-square-svg"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect className="treemap-legend-item" width="20" height="20" fill="#00BCD4" />
          </svg>
          <span>PSP</span>
        </div>
        <div className="treemap-legend-item">
          <svg
            className="treemap-square-svg"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect className="treemap-legend-item" width="20" height="20" fill="#FF5252" />
          </svg>
          <span>XOne</span>
        </div>
      </div>
    </div>
  );
};

export default Treemap;
