import { React, useEffect} from 'react';
import Cpp from '../Components/Cpp.js';

function CppView() {

  useEffect(() => {
    document.title = 'C++ | Mohamed Ayoub Eleuch';
  }, [])

  return (
      <Cpp />
  );
}

export default CppView;
