import React, { useEffect, useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../styles/D3.css';

import Navbar from './Navbar';
import ChoroplethMap from './Plots/ChoroplethMap';
import HeatMap from './Plots/HeatMap';
import Treemap from './Plots/Treemap';
import Scatterplot from './Plots/Scatterplot';
import Barchart from './Plots/Barchat';
import CodePen from './CodePen';
import { useParams } from 'react-router-dom';

function D3(){
    const [isLoaded, setIsLoaded] = useState(false);
    const [selectedProgram, setSelectedProgram] = useState(-1);
    const [programExists, setProgramExists] = useState(true);

    const programsList = [
        {
            'title': `Choropleth Map`,
            'link': `https://codepen.io/Mohamed-Ayoub-Eleuch/pen/RwmNxbR`,
            'id': 'choropleth_map',
            'component': ChoroplethMap
        },
        {
            'title': `Heat Map`,
            'link': `https://codepen.io/Mohamed-Ayoub-Eleuch/pen/oNRgBLm`,
            'id': 'heatmap',
            'component': HeatMap
        },
        {
            'title': `Treemap Diagram`,
            'link': `https://codepen.io/Mohamed-Ayoub-Eleuch/pen/eYamMqP`,
            'id': 'treemap',
            'component': Treemap
        },
        {
            'title': `Scatterplot Graph`,
            'link': `https://codepen.io/Mohamed-Ayoub-Eleuch/pen/BaeyQme`,
            'id': 'scatterplot',
            'component': Scatterplot
        },
        {
            'title': `Bar Chart`,
            'link': `https://codepen.io/Mohamed-Ayoub-Eleuch/pen/QWRwybr`,
            'id': 'barchart',
            'component': Barchart
        },
    ]

    const { id } = useParams();

    useEffect(() => {
        if (id && isLoaded) {
          const exists = programsList.findIndex(obj => obj.id === id);
          console.log(exists);
          if (exists>=0) {
            setProgramExists(true);
            handleLinkChange(exists);
          } else {
            setProgramExists(false);
          }
        }
      }, [id, isLoaded])

    const handleLinkChange  = (index) => {
        setSelectedProgram(index);
    }

    useEffect(() => {
        setIsLoaded(true);
    }, [])

    const forwardToLink = (link) => {
        window.open(link, '_blank');
    };

    const renderSelectedComponent = () => {
        if (selectedProgram >= 0) {
            const SelectedComponent = programsList[selectedProgram].component;
            return <SelectedComponent />;
        }
        return (<div className="d3-introduction">Here you will find my projects on data visualization using D3.js to get the 'Data Visualization' certificate at freeCodeCamp.org:
        <br></br>
        <br></br>
        <div onClick={() => forwardToLink('https://www.freecodecamp.org/certification/fccc9f79a51-50c3-4a1e-a839-2d9f958b9e20/data-visualization')} className={`d3-certificate_wrapper ${isLoaded ? '' : 'notLoaded'}`}>
            <img src={require('../Images/About/freecodecamp.png')} alt="freecodecamp logo"></img>
            <p>D3 Data Visualization</p>
        </div>
        <br></br>
        You can navigate through the projects from the menu on the left side of the screen.
        <br></br>
        <br></br>
        Please note that the plots are best shown on medium and large sized screens.
        </div>);
    };


    return (
        <div className="d3-wrapper">
            <Navbar programsList={programsList} sendDataToParent={handleLinkChange}/>
            <div className={`d3-plot_wrapper ${isLoaded ? '' : 'notLoaded'}`}>
                {
                    selectedProgram >= 0 ? <CodePen link={programsList[selectedProgram].link} /> : <></>
                }
                {renderSelectedComponent()}
            </div>
            <img className="d3-particles_background" src={require('../Images/Home/bg.png')} alt="background"/>
        </div>
    )
}

export default D3;