import * as d3 from 'd3';

const processData = (dataSet) => {
  const w = 1000;
  const h = 500;
   
  d3.select('.treemap-visHolder > *').remove();
  const svg = d3.select('.treemap-visHolder')
                .append('svg')
                .attr('width', w)
                .attr('height', h);
  
  d3.select('#treemap-tooltip').remove();

  const tooltip = d3.select('#treemap-wrapper')
                    .append('div')
                    .attr('id', 'treemap-tooltip')
                    .style('opacity', 0);
                     
  
  let hierarchy = d3.hierarchy(dataSet, data => {
    return data.children;
  }).sum((data) => {
    return data.value;
  }).sort((data1, data2) => {
    return data2.value - data1.value;
  });
  
  d3.treemap().size([w, h])(hierarchy);
  
  let gamesTiles = hierarchy.leaves();
  
  const block = svg.selectAll('g')
                   .data(gamesTiles)
                   .enter()
                   .append('g')
                   .attr('transform', (game) => `translate(${game.x0}, ${game.y0})`);
  
  block.append('rect')
       .attr('class', 'treemap-tile')
       .attr('fill', d => {
          const category = d.data.category;
          return pickColor(category);
        })
      .attr('data-name', d => d.data.name)
      .attr('data-category', d => d.data.category)
      .attr('data-value', d => d.data.value)
      .attr('width', d => (d.x1 - d.x0))
      .attr('height', d => (d.y1 - d.y0))
      .attr('stroke', '#fff')
      .attr('stroke-width', 1)
      .on('mouseover', (event, d) => {
        tooltip.transition().duration(250).style('opacity', 1);
      })
      .on('mousemove', (event, d) => {
        tooltip.html(`Name: ${d.data.name}<br>Category: ${d.data.category}<br>Value: ${d.data.value}`)
               .attr('data-value', d.data.value)
               .style('left', (event.x + 10) + 'px')
               .style('top', (event.y + 10) + 'px');
      })
      .on('mouseout', (event, d) => {
         tooltip.transition().duration(250).style('opacity', 0);
      });
  
  block.append('text')
       .text(d => d.data.name)
       .attr('x', 5)
       .attr('y', 15)
       .style('font-size', '10px');
};

const pickColor = (category) => {
  const categoryColors = {
        "Wii": "#009688",
        "DS": "#FF5722",
        "X360": "#3F51B5",
        "GB": "#8BC34A",
        "PS3": "#FFC107",
        "NES": "#673AB7",
        "PS2": "#F44336",
        "3DS": "#2196F3",
        "PS4": "#9C27B0",
        "SNES": "#FF9800",
        "PS": "#795548",
        "N64": "#CDDC39",
        "GBA": "#FFEB3B",
        "XB": "#607D8B",
        "PC": "#4CAF50",
        "2600": "#E91E63",
        "PSP": "#00BCD4",
        "XOne": "#FF5252"
    };
  
    return categoryColors[category];
};

export default processData;