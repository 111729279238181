import { React, useEffect } from "react";
import processData from "./Scatterplot/ScatterplotHelper";
import "./Scatterplot/Scatterplot.css";

const Scatterplot = () => {

    useEffect(() => {
        fetch('https://raw.githubusercontent.com/freeCodeCamp/ProjectReferenceData/master/cyclist-data.json')
            .then(response => {
                if(!response.ok){
                throw new Error('Error while fetching the data');
                }
                return response.json();
            })
            .then(data => {
                processData(data);
            })
            .catch(error => {
                console.error(error);
            });

            return () => {

            }
    }, [])

  return (
    <div id="scatterplot-wrapper">
      <div id="scatterplot-title">
        <h1>Doping in Professional Bicycle Racing</h1>
        <h3>35 Fastest times up Alpe d'Huez</h3>
      </div>
      <div className="scatterplot-visHolder"></div>
    </div>
  );
};


export default Scatterplot;