import React, { useEffect, useState } from 'react';
import '../styles/CodePen.css';

const CodePen = (props) => {
    const { link } = props;
    
    const forwardToLink = () => {
        window.open(link, '_blank');
    };


    return (
        <div onClick={forwardToLink} className="Codepen-wrapper">
            <p>Open source code on</p>
            <img src={require('../Images/D3/codepen.png')} alt="codepen logo"></img>
        </div>
    )
}

export default CodePen;