import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeView from './Views/HomeView';
import AboutView from './Views/AboutView';
import CppView from './Views/CppView';
import D3View from './Views/D3View';
import ReactView from './Views/ReactView';
import ProjectsView from './Views/ProjectsView';
import NotFoundView from './Views/NotFoundView';
import './App.css';

function App() {
  
  return (
    <div className="App">
      <Router>
          <Routes>
            <Route path="/" element={<HomeView />} />
            <Route path="/about" element={<AboutView />} />
            <Route path="/D3" element={<D3View />} />
            <Route path="/D3/:id" element={<D3View />} />
            <Route path="/Cpp" element={<CppView />} />
            <Route path="/Cpp/:id" element={<CppView />} />
            <Route path="/React" element={<ReactView />} />
            <Route path="/React/:id" element={<ReactView />} />
            <Route path="/website_projects" element={<ProjectsView />} />
            <Route path="*" element={<NotFoundView />} />
          </Routes>
      </Router>
    </div>
  );
}

export default App;
