import React, { useEffect } from "react";
import processData from "./Heatmap/HeatmapHelper";
import "./Heatmap/Heatmap.css";

const HeatMap = () => {
  useEffect(() => {
    const fetchDataAndProcess = () => {
      fetch(
        "https://raw.githubusercontent.com/freeCodeCamp/ProjectReferenceData/master/global-temperature.json"
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Error while fetching data");
          }
          return response.json();
        })
        .then((data) => {
            let w = 1200;
            let h = 400;
          processData(data, w, h);
        })
        .catch((e) => {
          console.error(e);
        });
    };

    const handleResize = () => {
        fetchDataAndProcess();
    };
    fetchDataAndProcess();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div id="heatmap-wrapper">
      <div>
        <h1 id="heatmap-title">Monthly Global Land-Surface Temperature</h1>
        <h3 id="heatmap-description">1753 - 2015: base temperature 8.66℃</h3>
        <br></br>
      </div>
      <div className="heatmap-visHolder"></div>
    </div>
  );
};

export default HeatMap;
