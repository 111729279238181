import React from 'react';
import NotFound from '../Components/NotFound.js';

function NotFoundView() {
  return (
      <NotFound />
  );
}

export default NotFoundView;
