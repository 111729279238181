import React, { useEffect } from "react";
import "./Choropleth/ChoroplethMap.css";
import processData from "./Choropleth/ChoroplethHelper";

const ChoroplethMap = () => {
  useEffect(() => {

    const fetchCountyData = async () => {
      try {
        const response = await fetch(
          "https://cdn.freecodecamp.org/testable-projects-fcc/data/choropleth_map/counties.json"
        );
        if (!response.ok) {
          throw new Error("Error while fetching county data");
        }
        return response.json();
      } catch (error) {
        console.error(error);
      }
    };

    const fetchEducationData = async () => {
      try {
        const response = await fetch(
          "https://cdn.freecodecamp.org/testable-projects-fcc/data/choropleth_map/for_user_education.json"
        );
        if (!response.ok) {
          throw new Error("Error while fetching education data");
        }
        return response.json();
      } catch (error) {
        console.error(error);
      }
    };

    fetchCountyData()
      .then((countyData) => {
        fetchEducationData()
            .then((educationData) => {
                processData(countyData, educationData);
            });
      })
      .catch((error) => {
        console.error(error);
      });

    return () => {

    };
  }, []);

  return (
    <div id="choropleth-wrapper">
      <h1 id="choropleth-title">United States Educational Attainment</h1>
      <h2 id="choropleth-description">
        Percentage of adults age 25 and older with a bachelor's degree or higher
        (2010-2014)
      </h2>
      <div id="choropleth-legend">
        <div className="choropleth-legend-item">
          <svg
            className="choropleth-square-svg"
            viewBox="0 0 40 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="40" height="20" fill="#F1DAC4" />
          </svg>
          <span>15%</span>
        </div>
        <div className="choropleth-legend-item">
          <svg
            className="choropleth-square-svg"
            viewBox="0 0 40 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="40" height="20" fill="#edc49d" />
          </svg>
          <span>30%</span>
        </div>
        <div className="choropleth-legend-item">
          <svg
            className="choropleth-square-svg"
            viewBox="0 0 40 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="40" height="20" fill="#f0b073" />
          </svg>
          <span>50%</span>
        </div>
        <div className="choropleth-legend-item">
          <svg
            className="choropleth-square-svg"
            viewBox="0 0 40 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="40" height="20" fill="#f0953e" />
          </svg>
          <span>100%</span>
        </div>
      </div>
      <div className="choropleth-visHolder"></div>
    </div>
  );
};

export default ChoroplethMap;
