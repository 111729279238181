import { React, useEffect} from 'react';
import Home from '../Components/Home.js';

function HomeView() {

  useEffect(() => {
    document.title = 'Mohamed Ayoub Eleuch | Portfolio';
  }, [])

  return (
      <Home />
  );
}

export default HomeView;
