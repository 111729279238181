import * as d3 from 'd3';
import * as choroplethTopojson from 'topojson-client';

const processData = (countyDataSet, educationDataSet) => {
    const w = 1000;
    const h = 700;
    d3.select('.choropleth-visHolder > *').remove();

    const svg = d3.select('.choropleth-visHolder')
                    .append('svg')
                    .attr('height', h)
                    .attr('width', w);

    const fillColors = ['#F1DAC4', '#edc49d', '#f0b073', '#f0953e'];
    const countyData = choroplethTopojson.feature(countyDataSet, countyDataSet.objects.counties).features;

    const tooltip = d3.select('#choropleth-wrapper')
                        .append('div')
                        .attr('id', 'choropleth-tooltip')
                        .style('opacity', 0);

    svg.selectAll('path')
        .data(countyData)
        .enter()
        .append('path')
        .attr('d', d3.geoPath())
        .attr('class', 'choropleth-county')
        .attr('fill', d => {
            const percent = educationDataSet.find((item) => (item.fips === d.id)).bachelorsOrHigher;
            if(percent <= 15) return fillColors[0];
            else if(percent <= 30) return fillColors[1];
            else if(percent <= 50) return fillColors[2];
            else return fillColors[3];
        })
        .attr('data-fips', d => d.id)
        .attr('data-education', d => educationDataSet.find((item) => (item.fips === d.id)).bachelorsOrHigher)
        .on('mouseover', (event, d) => {
            tooltip.transition().duration(250).style('opacity', 1);
            const data = educationDataSet.find((item) => item.fips === d.id);
            tooltip.html(`${data.area_name}, ${data.state}: ${data.bachelorsOrHigher}%`)
                .attr('data-education', data.bachelorsOrHigher)
                .style('left', (event.pageX + 10) + 'px')
                .style('top', (event.pageY + 10) +'px');
        })
        .on('mouseout', (event, d) => {
            tooltip.transition().duration(250).style('opacity', 0);
        });
};

export default processData;
