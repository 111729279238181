import React from 'react';
import About from '../Components/About.js';

function AboutView() {
  return (
      <About />
  );
}

export default AboutView;
