import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Navbar.css';

function Navbar(props){
    const [isLoaded, setIsLoaded] = useState(false);
    const [showNavbar, setShowNavbar] = useState(false);
    const [selectedProgram, setSelectedProgram] = useState(-1);
    const [section, setSection] = useState('');
    const { programsList, sendDataToParent } = props;
    const navigate = useNavigate();
    
    const handleProgramClick = (index) => {
        if(index === selectedProgram) return;
        handleNavbarItems(index);
        navigateUrl(index);
    }

    const handleNavbarItems = (index) => {
        const navbarItems = document.querySelectorAll('.navbar-nav_item');

        setSelectedProgram(index);

        navbarItems.forEach((item, itemIndex) => {
            if(index + 1 === itemIndex){
                item.classList.add('active_navbar_item');
            } else {
                item.classList.remove('active_navbar_item');
            }
        })
    }

    const navigateUrl = (index) => {
        navigate(`/${section}/${programsList[index].id}`);
        sendDataToParent(index);
    }

    const toggleNavbar = () => {
        setShowNavbar(!showNavbar);
    }

    useEffect(() => {
        setIsLoaded(true);

        const path = window.location.pathname;
        const parts = path.split('/').filter(part => part !== '');
        const id = parts[1];
        setSection(parts[0]);

        if(section && id){
            const index = programsList.findIndex(program => program.id === id);
            handleProgramClick(index);
        }
        
        return () => {

        }

    }, [navigate, programsList]);

    return (
        <>
        <div className={`navbar-nav_bar ${isLoaded ? '' : 'notLoaded'} ${showNavbar ? '' : 'hidden'}`} >
                <div onClick={() => navigate('/')} className="navbar-nav_item navbar-go_home">
                    &lt;- Go home
                </div>
                {
                    programsList.map((program, index) => (
                        <div key={index} className="navbar-nav_item" onClick={() => handleProgramClick(index)}>
                            <p>{ program.title }</p>
                        </div>
                    ))
                }

                <p onClick={toggleNavbar} className={`navbar-nav_bar_toggle ${showNavbar ? '' : 'hidden'}`}>{`${showNavbar? '✖' : '☰'}`}</p>
                </div>
        </>
    )
}


export default Navbar;