import { React, useEffect} from 'react';
import ReactComponent from '../Components/ReactComponent.js';

function ReactView() {

  useEffect(() => {
    document.title = 'Mohamed Ayoub Eleuch | React';
  }, [])

  return (
      <ReactComponent />
  );
}

export default ReactView;
