import { React, useEffect, useState } from 'react';
import '../styles/NotFound.css';


function NotFound() {
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        setIsLoaded(true);
    }, [])

    const forwardToLink = (link) => {
        window.location.href = link;
    };

    return (
        <div className="notfound-wrapper">
            <div className="notfound-text-wrapper">
            <h1 className={`notfound-text-h1 ${isLoaded ? '' : 'notLoaded'}`}>Page Not Found - 404</h1>
            <h3 className={`notfound-text-h3 ${isLoaded ? '' : 'notLoaded'}`}>Oops! Looks like you've ventured into uncharted celestial realms. You're adrift in the cosmic sea, lost among the stars. But fear not, even in the vastness of space, every journey holds a spark of discovery. Let's navigate back to familiar galaxies together!</h3>
            <button onClick={() => forwardToLink('/')} className={`notfound-text-button ${isLoaded ? '' : 'notLoaded'}`}>Go Back Home</button>
            </div>
        </div>
    )
}


export default NotFound;