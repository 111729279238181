import { React, useEffect, useState } from 'react';
import '../styles/WebsiteProjects.css';
import { queries } from '@testing-library/react';

function WebsiteProjects() {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setIsLoaded(true);
    }, [])

    const forwardToLink = (link) => {
        window.open(link, '_blank');
    };

    return (
        <div className="website-projects_wrapper">
            <div className={`website-projects_introduction ${isLoaded ? '' : 'notLoaded'}`}>
                <h3>Welcome to my website projects!</h3>
                <br></br>
                <p>Here you will find some of the websites that I made or participated in making it along with the technologies used.
                </p>
            </div>
            <div className={`website-projects_grid ${isLoaded ? '' : 'notLoaded'}`}>
                <div onClick={() => forwardToLink('http://aurumcirculi.mohamedayoubeleuch.com/')} className="website-projects_grid_item">
                    <img className="website-projects_grid_item_cover" src={require('../Images/WebsiteProjects/Rings_Ecommerce_cover.PNG')} alt="alto website cover"></img>
                    <div className="website-projects_data">
                        <p>Stack: React, Redux Toolkit, React Router, Javascript, HTML5, CSS3</p>
                        <p>Personal ecommerce project. Focused primarily on the functionality rather than the design, but still made it kind of fancy</p>
                        <p>Year: 2024</p>
                        <p>Role: All</p>
                    </div>
                </div>
                <div onClick={() => forwardToLink('http://reactbookapp.mohamedayoubeleuch.com/')} className="website-projects_grid_item">
                    <img className="website-projects_grid_item_cover" src={require('../Images/WebsiteProjects/Book_App_cover.PNG')} alt="alto website cover"></img>
                    <div className="website-projects_data">
                        <p>Stack: React, Redux, TypeScript, HTML5, CSS3</p>
                        <p>Personal project to simulate an app for book lookups (focused on functionality rather than style)</p>
                        <p>Year: 2023</p>
                        <p>Role: All</p>
                    </div>
                </div>
                <div onClick={() => forwardToLink('https://alto.mohamedayoubeleuch.com/')} className="website-projects_grid_item">
                    <img className="website-projects_grid_item_cover" src={require('../Images/WebsiteProjects/Alto_cover.PNG')} alt="alto website cover"></img>
                    <div className="website-projects_data">
                        <p>Stack: HTML5, CSS3, Javascript, PHP, jQuery</p>
                        <p>Company: Alto Detergents</p>
                        <p>Year: 2021/2022</p>
                        <p>Role: All</p>
                    </div>
                </div>
            </div>
            <img className="website-projects-particles_background" src={require('../Images/Home/bg.png')} alt="background"/>
        </div>
    )
}

export default WebsiteProjects;