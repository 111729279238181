import * as d3 from 'd3';

const processData = (dataSet) => {
  const h = 400;
  const w = 800;
  const padding = 40;

  d3.select('.scatterplot-visHolder > *').remove();

  const parseTime = d3.timeParse('%M:%S');

  dataSet.forEach(item => {
    let parsedTime = item.Time.split(':');
    item.Time = new Date(Date.UTC(1970, 0, 1, 0, parsedTime[0], parsedTime[1]));
  });
  const xScale = d3.scaleLinear()
                   .domain([d3.min(dataSet, d => d.Year - 1), d3.max(dataSet, d => d.Year + 1)])
                   .range([0, w]);

  const yScale = d3.scaleTime()
                      .domain(d3.extent(dataSet, d => d.Time))
                      .range([0, h]);

  const svg = d3.select('.scatterplot-visHolder')
                .append('svg')
                .attr('width', w + 100)
                .attr('height', h + 100);

  const xAxis = d3.axisBottom(xScale).tickFormat(d3.format('d'));
  const yAxis = d3.axisLeft(yScale).tickFormat(d3.timeFormat('%M:%S'));

  const tooltip = d3.select('#scatterplot-wrapper')
                    .append('div')
                    .attr('id', 'scatterplot-tooltip')
                    .style('opacity', 0);        

  svg.append('g')
     .attr('id', 'scatterplot-x-axis')
     .attr('transform', `translate(${padding + 20}, ${h+padding})`)
     .call(xAxis);

  svg.append('g')
     .attr('id', 'scatterplot-y-axis')
     .attr('transform', `translate(${padding + 20}, ${padding})`)
     .call(yAxis);

  svg.append('text')
     .attr('transform', 'rotate(-90)')
     .attr('x', -150)
     .attr('y', 15)
     .text('Time in Minutes');

  const legend = svg.append('g')
                    .attr('id', 'scatterplot-legend');

  const legend1 =  legend.append('g')
                         .attr('class', 'scatterplot-legend-label');
  const legend2 =  legend.append('g')
                         .attr('class', 'scatterplot-legend-label');

  legend1.append('rect')
         .attr('height', 20)
         .attr('width', 20)
         .attr('x', w + padding)
         .attr('y', h / 2 - 12.5)
         .attr('fill', 'rgb(115, 240, 58)');

  legend1.append('text')
         .attr('x', w + padding - 115)
         .attr('y', h / 2)
         .text('No doping allegations')
         .style('font-size', 12)
         .style('fill', '#FFF');

  legend2.append('rect')
         .attr('height', 20)
         .attr('width', 20)
         .attr('x', w + padding)
         .attr('y', h / 2 + 12.5)
         .attr('fill', 'rgb(240, 58, 58)');

  legend2.append('text')
         .attr('x', w + padding - 155)
         .attr('y', h / 2 + 25)
         .text('Riders with doping allegations')
         .style('font-size', 12)
         .style('fill', '#FFF');

  svg.selectAll('circle')
     .data(dataSet)
     .enter()
     .append('circle')
     .attr('class', 'scatterplot-dot')
     .attr('r', 6)
     .attr('data-xvalue', (d, i) => dataSet[i].Year)
     .attr('data-yvalue', (d, i) => dataSet[i].Time)
     .attr('cx', (d, i) => xScale(dataSet[i].Year) + padding + 20)
     .attr('cy', (d, i) => yScale(dataSet[i].Time) + padding)
     .attr('fill', (d, i) => {
        return dataSet[i].Doping === '' ? 'rgba(115, 240, 58, 0.6)' : 'rgba(240, 58, 58, 0.6)';
      })
     .attr('stroke', 'white')
     .attr('stroke-width', 1)
     .on('mouseover', (event, d) => {
        tooltip.transition().duration(250).style('opacity', 1);

        tooltip.html(`${d.Name}: ${d.Nationality}<br>Year: ${d.Year}, Time: ${d3.timeFormat('%M:%S')(d.Time)}<br><br>${d.Doping}`)
               .attr('data-year', d.Year)
               .style('left', event.pageX + 10 + 'px')
               .style('top', event.pageY + 'px');
      })
     .on('mouseout', (event, d) => {
        tooltip.transition().duration(250).style('opacity', 0);
      });
};

export default processData;