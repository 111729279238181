import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import "../styles/Cpp.css";

import Navbar from "./Navbar";
import programsList from './Data/CppData';
import NotFound from './NotFound';

function Cpp() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [programText, setProgramText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [programLoaded, setProgramLoaded] = useState(false);
  const [programExists, setProgramExists] = useState(true);

  const { id } = useParams();

  useEffect(() => {
    if (id && isLoaded) {
      const exists = programsList.findIndex(obj => obj.id === id);
      console.log(exists);
      if (exists>=0) {
        setProgramExists(true);
        handleLinkChange(exists);
      } else {
        setProgramExists(false);
      }
    }
  }, [id, isLoaded])

  const handleLinkChange = async (index) => {
    setProgramLoaded(false);
    const link = programsList[index].link;

    setCurrentIndex(index);

    if (link) {
      try {
        const response = await fetch(link);
        const programCode = await response.text();
        setTimeout(() => {
          setProgramText(programCode);
        }, 300);
      } catch (error) {
        console.error("Error fetching program text:", error);
      }
    } else {
      setTimeout(() => {
        setProgramText("Program text not available");
      }, 300);
    }
    setTimeout(() => {
      setProgramLoaded(true);
    }, 400);
  };

  const copyProgram = () => {
    if (programLoaded) {
      navigator.clipboard
        .writeText(programText)
        .then(() => {
          alert("Program text copied to clipboard");
        })
        .catch((error) => {
          alert("Error copying program text to clipboard:", error);
        });
    }
  };

  useEffect(() => {
    setIsLoaded(true);
  }, [programText]);

  const forwardToLink = (link) => {
    window.open(link, '_blank');
  };

  return (
    <>
      {
        !programExists &&
        <NotFound />
      }
      {
        programExists &&
        <div className="cpp-wrapper">
          <Navbar programsList={programsList} sendDataToParent={handleLinkChange} />

          <div className={`cpp-program_wrapper ${isLoaded ? "" : "notLoaded"}`}>
            {
              currentIndex === -1 ? (<></>)
                : (
                  <p
                    onClick={copyProgram}
                    className={`cpp-copy_btn ${isLoaded ? "" : "notLoaded"}`}
                  >
                    Copy
                  </p>
                )
            }
            {
              currentIndex === -1 ?
                (<div className="cpp-introduction">
                  Here you will find my own solutions on some of the competitive programing challenges using C++.
                  <br></br>
                  <br></br>
                  You can navigate through the solutions from the menu on the left side of the screen, or you can check my <span onClick={() => forwardToLink('https://github.com/AyoubElleuch')}>GitHub</span> for more solutions and projects.
                </div>)
                :
                (<SyntaxHighlighter
                  language="cpp"
                  className={`language-cpp ${programLoaded ? "" : "notLoaded"}`}
                  style={atomDark}
                >
                  {programText}
                </SyntaxHighlighter>)
            }
          </div>
          <img
            className="cpp-particles_background"
            src={require("../Images/Home/bg.png")}
            alt="background"
          />
        </div>
      }
    </>
  );
}

export default Cpp;
