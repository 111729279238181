import React, { useState } from 'react';
import './Calculator.css'; // Assuming you have a CSS file for styling

const Calculator = () => {
  const [input, setInput] = useState('');
  const [totalInput, setTotalInput] = useState('');
  const [decimal, setDecimal] = useState(false);
  const [evaluated, setEvaluated] = useState(false);
  const [result, setResult] = useState('');

  const clear = () => {
    setInput('');
    setTotalInput('');
    setDecimal(false);
    setResult('');
  };

  const evaluate = () => {
    if (input === '' || totalInput.endsWith('=')) return;

    let updatedTotalInput = '';
    if (!['/', '*', '+', '-'].includes(input)) {
      if (input === '0.') {
        updatedTotalInput = totalInput.slice(0, -1);
      } else {
        updatedTotalInput = totalInput + input;
      }
    } else {
      updatedTotalInput = totalInput;
    }

    try {
      const evaluation = eval(updatedTotalInput);

      setInput(evaluation.toString());
      setTotalInput(updatedTotalInput + '=');
      setDecimal(false);
      setEvaluated(true);
      setResult(evaluation.toString());
    } catch (error) {
      console.error('Evaluation error:', error);
      clear();
    }
  };

  const handleInput = (x) => {
    let current = input;
    let currentDecimal = decimal;
    let currentResult = result;
    let currentEvaluated = evaluated;
    let currentTotalInput = totalInput;
    let updated = '';

    if (currentEvaluated) {
      if (!['/', '*', '+', '-'].includes(x)) {
        currentResult = '';
        current = '';
      }
      currentTotalInput = '';
      currentEvaluated = false;
    }

    if (['/', '*', '+', '-'].includes(current) && !['/', '*', '+', '-'].includes(x)) {
      currentTotalInput = currentTotalInput + current;
      current = '';
    }

    if (x === '.') {
      if (!currentDecimal) {
        if (current === '') {
          updated = '0.';
        } else {
          updated = current + '.';
        }
        currentDecimal = true;
      } else {
        return;
      }
    }

    if (x !== '.') {
      if (current === '0') {
        updated = x;
      } else {
        updated = current + x;
      }
    }

    if (['/', '*', '+', '-'].includes(x)) {
      currentDecimal = false;
      if (!['/', '*', '+', '-'].includes(current)) {
        if (current === '0.') {
          current = '0';
          if (currentTotalInput !== '') {
            current = '';
            currentTotalInput = currentTotalInput.slice(0, -1);
          }
        }
        currentTotalInput = currentTotalInput + current;
      }
      updated = x;
    }

    setInput(updated);
    setTotalInput(currentTotalInput);
    setDecimal(currentDecimal);
    setEvaluated(currentEvaluated);
    setResult(currentResult);
  };

  return (
    <div id="react-calculator-wrapper">
      <div id="react-calculator-display">
        <p id="react-calculator-input">{totalInput + input}</p>
        <p>{input}</p>
      </div>
      <button id="react-calculator-zero" className="react-calculator-digits react-calculator-button" onClick={() => handleInput('0')}>0</button>
      <button id="react-calculator-one" className="react-calculator-digits react-calculator-button" onClick={() => handleInput('1')}>1</button>
      <button id="react-calculator-two" className="react-calculator-digits react-calculator-button" onClick={() => handleInput('2')}>2</button>
      <button id="react-calculator-three" className="react-calculator-digits react-calculator-button" onClick={() => handleInput('3')}>3</button>
      <button id="react-calculator-four" className="react-calculator-digits react-calculator-button" onClick={() => handleInput('4')}>4</button>
      <button id="react-calculator-five" className="react-calculator-digits react-calculator-button" onClick={() => handleInput('5')}>5</button>
      <button id="react-calculator-six" className="react-calculator-digits react-calculator-button" onClick={() => handleInput('6')}>6</button>
      <button id="react-calculator-seven" className="react-calculator-digits react-calculator-button" onClick={() => handleInput('7')}>7</button>
      <button id="react-calculator-eight" className="react-calculator-digits react-calculator-button" onClick={() => handleInput('8')}>8</button>
      <button id="react-calculator-nine" className="react-calculator-digits react-calculator-button" onClick={() => handleInput('9')}>9</button>
      <button id="react-calculator-decimal" className="react-calculator-button" onClick={() => handleInput('.')}>.</button>
      <button id="react-calculator-equals" className="react-calculator-button" onClick={evaluate}>=</button>
      <button id="react-calculator-clear" className="react-calculator-button" onClick={clear}>AC</button>
      <button id="react-calculator-divide" className="react-calculator-button" onClick={() => handleInput('/')}>/</button>
      <button id="react-calculator-multiply" className="react-calculator-button" onClick={() => handleInput('*')}>*</button>
      <button id="react-calculator-add" className="react-calculator-button" onClick={() => handleInput('+')}>+</button>
      <button id="react-calculator-subtract" className="react-calculator-button" onClick={() => handleInput('-')}>-</button>
    </div>
  );
};

export default Calculator;
