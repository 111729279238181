import { React, useEffect } from 'react';
import D3 from '../Components/D3.js';

function D3View() {

  useEffect(() => {
    document.title = 'Data Visualization D3 | Mohamed Ayoub Eleuch';
  }, [])

  return (
      <D3 />
  );
}

export default D3View;
