  import * as d3 from "d3";

  const processData = (dataSet, w, h) => {
    const padding = 50;
    const shiftToRight = 20;
    const shiftToTop = -20;
    const clusterWidth = 5;
    const clusterHeight = 30;
    const baseTemp = dataSet.baseTemperature;
    const legendCubeSide = 30;
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const colors = [
      "rgb(69, 117, 180)",
      "rgb(116, 173, 209)",
      "rgb(171, 217, 233)",
      "rgb(224, 243, 248)",
      "rgb(255, 255, 191)",
      "rgb(254, 224, 144)",
      "rgb(253, 174, 97)",
      "rgb(244, 109, 67)",
      "rgb(215, 48, 39)",
    ];
    const legendScale = [2.8, 3.9, 5.1, 6.1, 7.2, 8.3, 9.5, 10.6, 11.7, 12.8];

    const heatmapLegendXScale = d3
      .scaleBand()
      .domain([...legendScale.map((d) => d.toString())])
      .range([0, 300]);

    const heatmapLegendXAxis = d3
      .axisBottom(heatmapLegendXScale)
      .tickValues(legendScale.map((value) => value.toFixed(1)))
      .tickSize(5);

    d3.select('.heatmap-visHolder > *').remove();
    
    const svg = d3
    .select(".heatmap-visHolder")
    .append("svg")
    .attr("width", w)
    .attr("height", h);
    
    d3.select('#heatmap-tooltip').remove();


    const tooltip = d3
    .select("#heatmap-wrapper")
    .append("div")
    .attr("id", "heatmap-tooltip")
    .style("opacity", 0);
    
    const xScale = d3
    .scaleLinear()
    .domain([
      d3.min(dataSet.monthlyVariance, (d) => d.year),
      d3.max(dataSet.monthlyVariance, (d) => d.year) + 1,
    ])
      .range([padding, w - padding]);
      
    const yScale = d3
    .scaleBand()
      .domain(months)
      .range([padding / 2, h - padding / 2]);
      
      const xAxis = d3.axisBottom(xScale).tickFormat(d3.format("d"));
      
      const yAxis = d3.axisLeft(yScale);
      
      d3.select('#heatmap-legend').remove();

      const legend = d3
      .select("#heatmap-wrapper")
      .append("svg")
      .attr("id", "heatmap-legend")
      .attr("width", 330)
      .attr("height", 100);
      

    legend
      .selectAll("rect")
      .data(colors)
      .enter()
      .append("rect")
      .attr("width", legendCubeSide)
      .attr("height", legendCubeSide)
      .attr("x", (d, i) => i * legendCubeSide + 1 + 30)
      .attr("y", 0)
      .attr("fill", (d) => d)
      .attr("stroke", "#151515")
      .attr("stroke-width", "1");

    legend
      .append("g")
      .attr("transform", "translate(15," + legendCubeSide + ")")
      .call(heatmapLegendXAxis);

    svg
      .append("g")
      .attr(
        "transform",
        "translate(" +
          shiftToRight +
          "," +
          (h - (padding / 2 - 1) + shiftToTop) +
          ")"
      )
      .attr("id", "heatmap-x-axis")
      .call(xAxis);

    svg
      .append("g")
      .attr(
        "transform",
        "translate(" + (padding + shiftToRight) + "," + shiftToTop + ")"
      )
      .attr("id", "heatmap-y-axis")
      .call(yAxis);

    svg
      .append("text")
      .attr("transform", `translate(50,${h / 2 + shiftToTop})rotate(-90)`)
      .text("Months")
      .style("z-index", 10);

    svg
      .append("text")
      .attr("transform", `translate(${w / 2 + shiftToRight},${h})`)
      .text("Years")
      .style("z-index", 10);

    svg
      .selectAll(".heatmap-cell")
      .data(dataSet.monthlyVariance)
      .enter()
      .append("rect")
      .attr("width", clusterWidth)
      .attr("height", clusterHeight)
      .attr("class", "heatmap-cell")
      .attr("fill", (d, i) => heatmapColorPicker(baseTemp + d.variance))
      .attr("x", (d, i) => xScale(d.year) + shiftToRight)
      .attr("y", (d, i) => yScale(months[d.month - 1]) + shiftToTop)
      .attr("data-year", (d) => d.year)
      .attr("data-month", (d) => d.month - 1)
      .attr("data-temp", (d) => (baseTemp + d.variance).toFixed(1))
      .on("mouseover", (event, d) => {
        const rect = event.target;
        tooltip.transition().duration(0).style("opacity", 1);
        tooltip
          .html(
            `${d.year} - ${months[d.month - 1]}<br>${(
              baseTemp + d.variance
            ).toFixed(1)}°C<br>${d.variance.toFixed(1)}°C`
          )
          .attr("data-year", d.year)
          .style("left", `${event.pageX - 40}px`)
          .style("top", `${event.pageY + 20}px`);
        d3.select(rect)
          .transition()
          .duration(0)
          .style("stroke", "black")
          .style("stroke-width", "2px");
      })
      .on("mouseout", (event, d) => {
        const rect = event.target;
        tooltip.transition().duration(0).style("opacity", 0);

        d3.select(rect).transition().duration(0).style("stroke", "none");
      });
  };

  const heatmapColorPicker = (temp) => {
    if (temp <= 3.9) return "rgb(69, 117, 180)";
    if (temp >= 3.9 && temp <= 5.1) return "rgb(116, 173, 209)";
    if (temp >= 5.1 && temp <= 6.1) return "rgb(171, 217, 233)";
    if (temp >= 6.1 && temp <= 7.2) return "rgb(224, 243, 248)";
    if (temp >= 7.2 && temp <= 8.3) return "rgb(255, 255, 191)";
    if (temp >= 8.3 && temp <= 9.5) return "rgb(254, 224, 144)";
    if (temp >= 9.5 && temp <= 10.6) return "rgb(253, 174, 97)";
    if (temp >= 10.6 && temp <= 11.7) return "rgb(244, 109, 67)";
    if (temp >= 11.7) return "rgb(215, 48, 39)";
  };

  const calculateWidth = () => {
    return window.innerWidth > 1100 ? 1100 : window.innerWidth - 300;
  };
  
  const calculateHeight = () => {
    return window.innerHeight > 400 ? 400 : window.innerHeight;
  };

  export default processData;
