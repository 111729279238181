import { React, useEffect } from 'react';
import WebsiteProjects from '../Components/WebsiteProjects';


function ProjectsView() {
    useEffect(() => {
        document.title = 'Website Projects | Mohamed Ayoub Eleuch';
      }, [])

    return (
        <div>
            <WebsiteProjects />
        </div>
    )
}

export default ProjectsView;