import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from './Navbar';
import CodePen from './CodePen';
import '../styles/React.css'

import Calculator from './React-projects-FCC/Calculator';
import DrumMachine from './React-projects-FCC/DrumMachine';
import ReactClock from './React-projects-FCC/Clock';
import MarkdownPreviewer from './React-projects-FCC/MarkdownPreviewer';
import QuoteMachine from './React-projects-FCC/QuoteMachine';

function ReactComponent() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [programExists, setProgramExists] = useState(true);

    const programsList = [
        {
            'title': `Calculator`,
            'link': `https://codepen.io/Mohamed-Ayoub-Eleuch/pen/xxmXKaz`,
            'id': 'calculator',
            'component': Calculator
        },
        {
            'title': `Drum Machine`,
            'link': `https://codepen.io/Mohamed-Ayoub-Eleuch/pen/xxmLjvX`,
            'id': `drum_machine`,
            'component': DrumMachine
        },
        {
            'title': `25 + 5 Clock`,
            'link': `https://codepen.io/Mohamed-Ayoub-Eleuch/pen/QWRLRRp`,
            'id': `25_plus_5_clock`,
            'component': ReactClock
        },
        {
            'title': `Markdown Previewer`,
            'link': `https://codepen.io/Mohamed-Ayoub-Eleuch/pen/oNJWNNL`,
            'id': 'markdown_previewer',
            'component': MarkdownPreviewer
        },
        {
            'title': `Quote Machine`,
            'link': `https://codepen.io/Mohamed-Ayoub-Eleuch/full/zYyZMBG`,
            'id': 'quote_machine',
            'component': QuoteMachine
        },
    ]

    const { id } = useParams();

    useEffect(() => {
      if (id && isLoaded) {
        const exists = programsList.findIndex(obj => obj.id === id);
        console.log(exists);
        if (exists>=0) {
          setProgramExists(true);
          handleProjectChange(exists);
        } else {
          setProgramExists(false);
        }
      }
    }, [id, isLoaded])

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    const handleProjectChange = (index) => {
        setCurrentIndex(index);
    }

    const forwardToLink = (link) => {
        window.open(link, '_blank');
    };

    const renderSelectedComponent = () => {
        if (currentIndex >= 0) {
            const SelectedComponent = programsList[currentIndex].component;
            return <SelectedComponent />;
        }
        return (<div className="react-introduction">Here you will find my React projects to get the 'Front End Development Libraries' certificate at freeCodeCamp.org:
        <br></br>
        <br></br>
        <div onClick={() => forwardToLink('https://www.freecodecamp.org/certification/fccc9f79a51-50c3-4a1e-a839-2d9f958b9e20/front-end-development-libraries')} className={`react-certificate_wrapper ${isLoaded ? '' : 'notLoaded'}`}>
            <img src={require('../Images/About/freecodecamp.png')} alt="freecodecamp logo"></img>
            <p>Front End Development Libraries</p>
        </div>
        <br></br>
        You can navigate through the projects from the menu on the left side of the screen.
        <br></br>
        <br></br>
        Please note that the projects are best shown on medium and large sized screens.
        </div>);
    };

    return (
        <div className="react-wrapper">
            <Navbar programsList={programsList} sendDataToParent={handleProjectChange} />
            <div className="react-project_wrapper">
                {
                    currentIndex >= 0 ? <CodePen link={programsList[currentIndex].link} /> : <></>
                }
                {renderSelectedComponent()}
            </div>
            <img className="particles_background" src={require('../Images/Home/bg.png')} alt="background" />
        </div>
    )
}

export default ReactComponent;