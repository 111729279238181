import * as d3 from "d3";

const processData = (dataSet) => {
  const h = 350;
  const w = 800;
  const padding = 5;

  d3.select('.barchart-visHolder > *').remove();

  const yearsQuarters = dataSet.map((item) => {
    const year = item[0].substring(0, 4);
    let Q = item[0].substring(5, 7);

    if (Q == "01") {
      Q = "Q1";
    } else if (Q == "04") {
      Q = "Q2";
    } else if (Q == "07") {
      Q = "Q3";
    } else {
      Q = "Q4";
    }

    return `${year} ${Q}`;
  });

  const years = dataSet.map((item) => {
    return new Date(item[0]);
  });

  const GDP = dataSet.map((item) => {
    return item[1];
  });

  const gdpScale = d3
    .scaleLinear()
    .domain([0, d3.max(GDP)])
    .range([0, h]);

  const scaledGDP = GDP.map((d) => gdpScale(d));

  // console.log(scaledGDP);

  const svg = d3
    .select(".barchart-visHolder")
    .append("svg")
    .attr("width", w + 100)
    .attr("height", h + 150);

  d3.select("#barchart-tooltip").remove();

  const tooltip = d3
    .select(".barchart-visHolder")
    .append("div")
    .attr("id", "barchart-tooltip")
    .style("opacity", 0)
    .style("color", "black");

  const xScale = d3
    .scaleTime()
    .domain([new Date(d3.min(years)), new Date(d3.max(years))])
    .range([0, w]);
  const yScale = d3
    .scaleLinear()
    .domain([0, d3.max(GDP)])
    .range([h, 0]);

  const xAxis = d3.axisBottom(xScale);
  const yAxis = d3.axisLeft(yScale);

  svg
    .append("g")
    .attr("id", "barchart-x-axis")
    .attr("transform", `translate(50, ${h + 50})`)
    .call(xAxis);

  svg
    .append("g")
    .attr("id", "barchart-y-axis")
    .attr("transform", "translate(50, 50)")
    .call(yAxis);

  svg
    .append("text")
    .attr("transform", "rotate(-90)")
    .attr("x", -300)
    .attr("y", 80)
    .text("Gross Domestic Product");

  svg
    .append("text")
    .attr("x", w / 2 + 40)
    .attr("y", h + 100)
    .text("More Information: http://www.bea.gov/national/pdf/nipaguid.pdf")
    .style('fill', 'white');

  svg
    .selectAll(".barchart-rect")
    .data(scaledGDP)
    .enter()
    .append("rect")
    .attr("width", w / 275)
    .attr("height", (d) => d)
    .attr("index", (d, i) => i)
    .attr("x", (d, i) => 50 + i * (w / 275))
    .attr("y", (d) => h - d + 50)
    .attr("data-date", (d, i) => {
      return dataSet[i][0];
    })
    .attr("data-gdp", (d, i) => {
      return dataSet[i][1];
    })
    .attr("class", "barchart-bar")
    .style("fill", "#F1DAC4")
    .on("mouseover", (event, i) => {
      const rect = event.target;
      const date = yearsQuarters[rect.getAttribute("index")];
      const gdp = rect.getAttribute("data-gdp");
      tooltip.transition().duration(250).style("opacity", 1);
      d3.select(rect).transition().duration(100).style("fill", "gray");

      tooltip
        .html(`Date: ${date}<br>GDP: $${gdp} Billion`)
        .attr("data-date", rect.getAttribute("data-date"))
        .style("left", event.pageX + 10 + "px")
        .style("top", event.pageY + 10 + "px");
    })
    .on("mouseout", (event, i) => {
      const rect = event.target;
      d3.select(rect).transition().duration(100).style("fill", "#F1DAC4");
      tooltip.transition().duration(250).style("opacity", 0);
    });
};

export default processData;
