import React, { useEffect, useState } from 'react';
import '../styles/About.css';

function About(){
    const [isLoaded, setIsLoaded] = useState(false);
    const [name, setName] = useState('');
    const myName = 'Mohamed Ayoub Eleuch';

    useEffect(() => {
        setIsLoaded(true);
    }, [])


    useEffect(() => {
        let i = -1;

        function writeName(){
            if(i < myName.length - 1){
                setName((prev) => prev.substring(0, prev.length - 1) + myName[i] + '_');
                i = i + 1;
                setTimeout(writeName, 50);
            }else{
                setName((prev) => prev.substring(0, myName.length));
            }
        }

        if(isLoaded){
            writeName();
        }

    }, [isLoaded, myName])

    useEffect(() => {
        const certificateWrappers = document.querySelectorAll('.about-certificate_wrapper');

        const handleMouseOver = (event) => {
            certificateWrappers.forEach((wrapper) => {
                if(wrapper !== event.currentTarget){
                    wrapper.style.opacity = '0.5';
                    wrapper.style.transform = 'scale(0.95)';
                }
            })
        }

        const handleMouseOut = () => {
            certificateWrappers.forEach((wrapper) => {
                wrapper.style.opacity = '1';
                wrapper.style.transform = 'scale(1)';
            })
        }

        certificateWrappers.forEach((wrapper) => {
            wrapper.addEventListener('mouseover', handleMouseOver);
            wrapper.addEventListener('mouseout', handleMouseOut);
        })
    })

    const forwardToLink = (link) => {
        window.open(link, '_blank');
    };

    return(
        <div className="about-wrapper">
            <h1 className={`about-name ${isLoaded ? '' : 'notLoaded'}`}>{ name }</h1>
            <div className={`about-social_media_wrapper ${isLoaded ? '' : 'notLoaded'}`}>
                <img onClick={() => forwardToLink('https://www.linkedin.com/in/mohamed-ayoub-eleuch-0815aa27a/')} src={require('../Images/About/linkedin.png')} alt="linkedin logo"></img>
                <img onClick={() => forwardToLink('https://github.com/AyoubElleuch')} src={require('../Images/About/github.png')} alt="linkedin logo"></img>
                <img onClick={() => forwardToLink('https://www.instagram.com/ayoubeleuch/')} src={require('../Images/About/instagram.png')} alt="linkedin logo"></img>
            </div>
            <p className={`about-paragraph ${isLoaded ? '' : 'notLoaded'}`}>
            I'm Mohamed Ayoub Eleuch, a 20-year-old self-taught programmer passionate about crafting efficient web solutions. With four years of experience in HTML, CSS, and JavaScript, I've built a strong foundation in front-end development. My journey extends to C and C++ for two years, enhancing my problem-solving skills.
            <br></br>
            <br></br>
            In the past year, I've embraced Vue.js and React.js, crafting dynamic web apps. Expertise in D3.js allows me to visualize data intricately. My self-driven learning reflects a commitment to growth in tech. Thriving on challenges, I eagerly contribute to new projects.
            <br></br>
            {/* <br></br>
            I am also proud to have earned several certifications from freeCodeCamp, which include Responsive Web Design, JavaScript Algorithms and Data Structures, Front End Development Libraries, and D3 Data Visualization. These certificates not only validate my skills but also demonstrate my commitment to continuous learning and professional development in web development. */}
            </p>

            <div className={`about-certificates_wrapper`}>
                <p className={`about-certificates_title ${isLoaded ? '' : 'notLoaded'}`}>Certificates</p>
                <div onClick={() => forwardToLink('https://www.freecodecamp.org/certification/fccc9f79a51-50c3-4a1e-a839-2d9f958b9e20/data-visualization')} className={`about-certificate_wrapper ${isLoaded ? '' : 'notLoaded'}`}>
                    <img src={require('../Images/About/freecodecamp.png')} alt="freecodecamp logo"></img>
                    <p>D3 Data Visualization</p>
                </div>
                <div onClick={() => forwardToLink('https://www.freecodecamp.org/certification/fccc9f79a51-50c3-4a1e-a839-2d9f958b9e20/front-end-development-libraries')} className={`about-certificate_wrapper ${isLoaded ? '' : 'notLoaded'}`}>
                    <img src={require('../Images/About/freecodecamp.png')} alt="freecodecamp logo"></img>
                    <p>Front End Development Libraries</p>
                </div>
                <div onClick={() => forwardToLink('https://www.freecodecamp.org/certification/fccc9f79a51-50c3-4a1e-a839-2d9f958b9e20/javascript-algorithms-and-data-structures-v8')} className={`about-certificate_wrapper ${isLoaded ? '' : 'notLoaded'}`}>
                    <img src={require('../Images/About/freecodecamp.png')} alt="freecodecamp logo"></img>
                    <p>JavaScript Algorithms and Data Structures</p>
                </div>
                <div onClick={() => forwardToLink('https://www.freecodecamp.org/certification/fccc9f79a51-50c3-4a1e-a839-2d9f958b9e20/responsive-web-design')} className={`about-certificate_wrapper ${isLoaded ? '' : 'notLoaded'}`}>
                    <img src={require('../Images/About/freecodecamp.png')} alt="freecodecamp logo"></img>
                    <p>Responsive Web Design</p>
                </div>
            </div>
            <img className={`about-saturn_img ${isLoaded ? '' : 'notLoaded'}`} src={require('../Images/About/satrun.png')} alt="saturn" />
            <img className="about-particles_background" src={require('../Images/Home/bg.png')} alt="background"/>
        </div>
    )
}

export default About;