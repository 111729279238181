import React, { useState, useEffect, useRef } from "react";
import './Clock.css';

const ReactClock = () => {
    const [breakLength, setBreakLength] = useState(300);
    const [sessionLength, setSessionLength] = useState(1500);
    const [session, setSession] = useState(true);
    const [remainingTime, setRemainingTime] = useState(1500);
    const [timerRunning, setTimerRunning] = useState(false);
    const timerIntervalRef = useRef(null);

    const updateBreak = (t) => {
        let newBreakLength = breakLength + t;
        newBreakLength = Math.min(Math.max(newBreakLength, 60), 3600);
        setBreakLength(newBreakLength);
    };

    const updateSession = (t) => {
        let newSessionLength = sessionLength + t;
        newSessionLength = Math.min(Math.max(newSessionLength, 60), 3600);
        setSessionLength(newSessionLength);
        setRemainingTime(newSessionLength);
    };

    const reset = () => {
        clearInterval(timerIntervalRef.current);
        const beepElement = document.getElementById("react-clock-beep");
        beepElement.pause();
        beepElement.currentTime = 0;
        setBreakLength(60);
        setSessionLength(1500);
        setSession(true);
        setRemainingTime(1500);
        setTimerRunning(false);
    };

    const toggleTimer = () => {
        if (!timerRunning) {
            setTimerRunning(true);
            timerIntervalRef.current = setInterval(() => {
                setRemainingTime(prevTime => {
                    if (prevTime === 0) {
                        const newSession = !session;
                        const newLength = newSession ? sessionLength : breakLength;
                        setSession(newSession);
                        document.getElementById("react-clock-beep").play();
                        return newLength;
                    } else {
                        return prevTime - 1;
                    }
                });
            }, 1000);
        } else {
            clearInterval(timerIntervalRef.current);
            setTimerRunning(false);
        }
    };

    return (
        <>
            <div>
                <div className="react-clock-title-wrapper">
                    <h1>25 + 5 Clock</h1>
                </div>
                <div className="react-clock-break-session-wrapper">
                    <div className="react-clock-break-wrapper">
                        <h3 id="react-clock-break-label">Break Length</h3>
                        <div className="react-clock-break-counter-wrapper">
                            <button onClick={() => updateBreak(-60)}>-</button>
                            <h3>{breakLength / 60}</h3>
                            <button onClick={() => updateBreak(60)}>+</button>
                        </div>
                    </div>
                    <div className="react-clock-session-wrapper">
                        <h3 id="react-clock-session-label">Session Length</h3>
                        <div className="react-clock-session-counter-wrapper">
                            <button onClick={() => updateSession(-60)}>-</button>
                            <h3>{sessionLength / 60}</h3>
                            <button onClick={() => updateSession(60)}>+</button>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="react-clock-clock-wrapper">
                        <h2 id="react-clock-timer-label">{session ? "Session" : "Break"}</h2>
                        <h3 id="react-clock-timer-left">
                            {Math.floor(remainingTime / 60) < 10
                                ? "0" + Math.floor(remainingTime / 60)
                                : Math.floor(remainingTime / 60)}
                            :
                            {remainingTime % 60 === 0
                                ? "00"
                                : remainingTime % 60 < 10
                                ? "0" + (remainingTime % 60)
                                : remainingTime % 60}
                        </h3>
                        <div className="react-clock-clock-btns-wrapper">
                            <button
                                className="react-clock-btn"
                                onClick={toggleTimer}
                                disabled={breakLength === 0 || sessionLength === 0}
                            >
                                {timerRunning ? "Pause" : "Start"}
                            </button>
                            <button className="react-clock-btn" onClick={reset}>
                                Reset
                            </button>
                            <audio
                                id="react-clock-beep"
                                src="https://cdn.freecodecamp.org/testable-projects-fcc/audio/BeepSound.wav"
                            ></audio>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReactClock;
