import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Home.css'

function Home() {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    useEffect(() => {
        const updateStackPositions = () => {
            const stackWrappers = document.querySelectorAll('.stack_wrapper');
            const stackHolders = document.querySelectorAll('.stack_wrapper_holder');
    
            const angleIncrement = window.innerWidth > 760 ? 37 : 90;
            const stackCircle = document.querySelector('.stack_circle');
            const stackCircleWidth = stackCircle.offsetWidth;
            const radius = stackCircleWidth / 2;
    
            stackWrappers.forEach((stackWrapper, index) => {
                const angleDegree = angleIncrement * (index + 1);
                const angle = angleDegree * (Math.PI / 180);
    
                const x = Math.sin(angle) * radius;
                const y = -Math.cos(angle) * radius;
    
                stackWrapper.style.left = (radius - 40 + y) + 'px';
                stackWrapper.style.top = (radius - x - 40) + 'px';
    
                stackHolders[index].style.left = (radius - 40 + y) + 'px';
                stackHolders[index].style.top = (radius - x - 40) + 'px';
            });
        };
    
        updateStackPositions();
    
        const resizeObserver = new ResizeObserver(updateStackPositions);
    
        const stackCircle = document.querySelector('.stack_circle');
        resizeObserver.observe(stackCircle);
    
        return () => {
            resizeObserver.disconnect();
        };
    }, []);


    useEffect(() => {
        const stackWrappers = document.querySelectorAll('.stack_wrapper');
        const tooltip = document.querySelector('#tooltip');
        const circle = document.querySelector('.stack_circle');

        const tooltipContent = {
            'html': 'Websites that I have made or have participated in its development. React, Vue, Javascript and much more',
            'js': 'Projects for the Algorithms and Data Structures certificate at freeCodeCamp',
            'd3': 'Projects on data visualization using D3.js',
            'react': 'Small React projects for FCC',
            'cpp': 'C++ solutions for competitive programming'
        }

        const handleMouseOver = (event) => {
            const stackType = event.currentTarget.getAttribute('data-stack-type');
            tooltip.textContent = tooltipContent[stackType];
            tooltip.style.opacity = '1';
            tooltip.style.zIndex = 3;
            stackWrappers.forEach((wrapper) => {
                if (wrapper !== event.currentTarget) {
                    wrapper.style.opacity = '0.5';
                    wrapper.style.transform = 'scale(0.8)';
                }
            });
            circle.style.borderColor = 'rgba(241, 218, 196, 0.5)';
        }   

        const handleMouseMove = (event) => {
            const { pageX, pageY } = event;

            tooltip.style.left = `${pageX + 10}px`;
            tooltip.style.top = `${pageY + 10}px`;
        };

        const handleMouseOut = () => {
            tooltip.style.opacity = '0';
            tooltip.style.zIndex = 2;   
            stackWrappers.forEach((wrapper) => {
                wrapper.style.opacity = '1';
                wrapper.style.transform = 'scale(1)';
            });
            circle.style.opacity = '1';
            circle.style.borderColor = 'rgba(241, 218, 196, 1)';
        };

        stackWrappers.forEach((stackWrapper) => {
            stackWrapper.addEventListener('mouseover', handleMouseOver);
            stackWrapper.addEventListener('mousemove', handleMouseMove);
            stackWrapper.addEventListener('mouseout', handleMouseOut);
        });
    
        return () => {
            stackWrappers.forEach((stackWrapper) => {
                stackWrapper.removeEventListener('mouseover', handleMouseOver);
                stackWrapper.removeEventListener('mousemove', handleMouseMove);
                stackWrapper.removeEventListener('mouseout', handleMouseOut);
            });
        };
    })

    const forwardToLink = (link) => {
        window.location.href = link;
    };

    return(
        <div className="wrapper">
            {/* <div className="jupiter">
                <div></div>
            </div> */}
            <Link to="/about" className={`learn_btn ${isLoaded ? '' : 'notLoaded'}`}>ABOUT ME</Link>
            <div id="tooltip">
                My small projects using REACT
            </div>

            <div id="landing_text_wrapper">
                <div className={`Title ${isLoaded ? '' : 'notLoaded'}`}>
                    MOHAMED AYOUB ELEUCH
                </div>
                <div className={`Position ${isLoaded ? '' : 'notLoaded'}`}>
                    FRONT END DEVELOPER 
                </div>
                <div className="skills_wrapper">
                    <p className={`skill ${isLoaded ? '' : 'notLoaded'}`}>Vue</p>
                    <p className={`skill ${isLoaded ? '' : 'notLoaded'}`}>React</p>
                    <p className={`skill ${isLoaded ? '' : 'notLoaded'}`}>D3.js</p>
                    <p className={`skill ${isLoaded ? '' : 'notLoaded'}`}>Javascript</p>
                    <p className={`skill ${isLoaded ? '' : 'notLoaded'}`}>C++</p>
                    <p className={`skill ${isLoaded ? '' : 'notLoaded'}`}>VueX</p>
                    <p className={`skill ${isLoaded ? '' : 'notLoaded'}`}>Redux</p>
                    <p className={`skill ${isLoaded ? '' : 'notLoaded'}`}>Git/Github</p>
                    <p className={`skill ${isLoaded ? '' : 'notLoaded'}`}>HTML/CSS</p>
                    <p className={`skill ${isLoaded ? '' : 'notLoaded'}`}>CP</p>
                </div>
            </div>

            <div className="figure_space">
                <img className={`jupiter_picture ${isLoaded ? '': 'notLoaded'}`} src={require('../Images/Home/jupiter_picture.png')} alt="jupiter"/>

                <div className={`stack_circle ${isLoaded ? '' : 'notLoaded'}`}>
                    <div className="stack_wrapper_holder"></div>
                    {/* <div className="stack_wrapper_holder"></div> */}
                    <div className="stack_wrapper_holder"></div>
                    <div className="stack_wrapper_holder"></div>
                    <div className="stack_wrapper_holder"></div>
                    <div onClick={() => forwardToLink('/website_projects')} className="stack_wrapper" data-stack-type="html">
                        <img src={require('../Images/Home/HTML_icon.png')} alt="html icon"/> 
                    </div>
                    {/* <div className="stack_wrapper" data-stack-type="js">
                        <img src={require('../Images/Home/JS_icon.png')} alt="js icon"/> 
                    </div> */}
                    <div onClick={() => forwardToLink('/React')} className="stack_wrapper" data-stack-type="react">
                        <img src={require('../Images/Home/React_icon.png')} alt="react icon"/> 
                    </div>
                    <div onClick={() => forwardToLink('/D3')} className="stack_wrapper" data-stack-type="d3">
                        <img src={require('../Images/Home/D3_icon.png')} alt="d3 icon"/> 
                    </div>
                    <div onClick={() => forwardToLink('/cpp')} className="stack_wrapper" data-stack-type="cpp">
                        <img src={require('../Images/Home/CPP_icon.png')} alt="c++ icon" id="cpp"/> 
                    </div>
                </div>
            </div>
            <img className="particles_background" src={require('../Images/Home/bg.png')} alt="background"/>
        </div>
    )
}

export default Home;