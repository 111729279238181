import { React, useEffect } from 'react';
import processData from './Barchart/BarchartHelper';
import './Barchart/Barchart.css';

const Barchart = () => {
    
    useEffect(() => {
        fetch('https://raw.githubusercontent.com/freeCodeCamp/ProjectReferenceData/master/GDP-data.json')
        .then(response => {
            if(!response.ok){
            throw new Error('Error while fetching the data');
            }
            return response.json();
        })
        .then(data => {
            processData(data.data)
        })
        .catch(error => {
        console.error(error);
        });

        return () => {
            
        }
    }, [])

    return (
        <div id="barchart-wrapper">
        <div id="barchart-title">
            United States GDP
        </div>
        <div className="barchart-visHolder">
            
        </div>
        </div>
    )
}

export default Barchart;