import React, { useState } from 'react';
import { marked } from 'marked';
import './MarkdownPreviewer.css'; 

const MarkdownPreviewer = () => {
  const [input, setInput] = useState('');

  const updateInput = (event) => {
    setInput(event.target.value);
  }

  const markedInput = marked.parse(input);

  return (
    <div className="react-markdown-wrapper">
      <div id="react-markdown-textarea-wrapper">
        <div id="react-markdown-editor-header">Editor</div>
        <textarea id="react-markdown-editor" value={input} onChange={updateInput}></textarea>
      </div>
      <div id="react-markdown-preview-wrapper">
        <div id="react-markdown-preview-header">Preview</div>
        <div id="react-markdown-preview" dangerouslySetInnerHTML={{ __html: markedInput }}></div>
      </div>
    </div>
  );
};

export default MarkdownPreviewer;
