import React, { useState, useEffect } from 'react';
import './DrumMachine.css'; // Assuming you have a CSS file for styling

const DrumMachine = () => {
    const [displayText, setDisplayText] = useState('');

    useEffect(() => {
        window.addEventListener("keydown", handleKeyPress);
        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    }, []);

    const handleBtn = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.classList.add('react-drum-machine-drum-pad-clicked');
            setTimeout(() => {
                element.classList.remove('react-drum-machine-drum-pad-clicked');
            }, 150);
        }
    }

    const playDrum = (id) => {
        document.getElementById(id).play();
        switch (id) {
            case 'Q':
                setDisplayText("Heater 1");
                handleBtn('react-drum-machine-heater-1');
                return;
            case 'W':
                setDisplayText("Heater 2");
                handleBtn('react-drum-machine-heater-2');
                return;
            case 'E':
                setDisplayText("Heater 3");
                handleBtn('react-drum-machine-heater-3');
                return;
            case 'A':
                setDisplayText("Heater 4");
                handleBtn('react-drum-machine-heater-4');
                return;
            case 'S':
                setDisplayText("Clap");
                handleBtn('react-drum-machine-clap');
                return;
            case 'D':
                setDisplayText("Open HH");
                handleBtn('react-drum-machine-open-hh');
                return;
            case 'Z':
                setDisplayText("Kick n' Hat");
                handleBtn('react-drum-machine-kick-n-hat');
                return;
            case 'X':
                setDisplayText("Kick");
                handleBtn('react-drum-machine-kick');
                return;
            case 'C':
                setDisplayText("Closed HH");
                handleBtn('react-drum-machine-closed-hh');
                return;
            default:
                return;
        }
    }

    const handleKeyPress = (event) => {
        const key = event.key.toUpperCase();
        if (key === 'Q' || key === 'W' || key === 'E' || key === 'A' || key === 'S' || key === 'D' || key === 'Z' || key === 'X' || key === 'C') {
            playDrum(key);
            return;
        }
    }

    return (
        <div className="react-drum-machine-body">
            <div id="react-drum-machine-drum-pads-wrapper">
                <div className="react-drum-machine-drum-pad" id="react-drum-machine-heater-1" onClick={() => { playDrum('Q') }}>
                    <audio className="react-drum-machine-clip" id="Q" src="https://s3.amazonaws.com/freecodecamp/drums/Heater-1.mp3"></audio>
                    Q
                </div>
                <div className="react-drum-machine-drum-pad" id="react-drum-machine-heater-2" onClick={() => { playDrum('W') }}>
                    <audio className="react-drum-machine-clip" id="W" src="https://s3.amazonaws.com/freecodecamp/drums/Heater-2.mp3"></audio>
                    W
                </div>
                <div className="react-drum-machine-drum-pad" id="react-drum-machine-heater-3" onClick={() => { playDrum('E') }}>
                    <audio className="react-drum-machine-clip" id="E" src="https://s3.amazonaws.com/freecodecamp/drums/Heater-3.mp3"></audio>
                    E
                </div>
                <div className="react-drum-machine-drum-pad" id="react-drum-machine-heater-4" onClick={() => { playDrum('A') }}>
                    <audio className="react-drum-machine-clip" id="A" src="https://s3.amazonaws.com/freecodecamp/drums/Heater-4_1.mp3"></audio>
                    A
                </div>
                <div className="react-drum-machine-drum-pad" id="react-drum-machine-clap" onClick={() => { playDrum('S') }}>
                    <audio className="react-drum-machine-clip" id="S" src="https://s3.amazonaws.com/freecodecamp/drums/Heater-6.mp3"></audio>
                    S
                </div>
                <div className="react-drum-machine-drum-pad" id="react-drum-machine-open-hh" onClick={() => { playDrum('D') }}>
                    <audio className="react-drum-machine-clip" id="D" src="https://s3.amazonaws.com/freecodecamp/drums/Dsc_Oh.mp3"></audio>
                    D
                </div>
                <div className="react-drum-machine-drum-pad" id="react-drum-machine-kick-n-hat" onClick={() => { playDrum('Z') }}>
                    <audio className="react-drum-machine-clip" id="Z" src="https://s3.amazonaws.com/freecodecamp/drums/Kick_n_Hat.mp3"></audio>
                    Z
                </div>
                <div className="react-drum-machine-drum-pad" id="react-drum-machine-kick" onClick={() => { playDrum('X') }}>
                    <audio className="react-drum-machine-clip" id="X" src="https://s3.amazonaws.com/freecodecamp/drums/RP4_KICK_1.mp3"></audio>
                    X
                </div>
                <div className="react-drum-machine-drum-pad" id="react-drum-machine-closed-hh" onClick={() => { playDrum('C') }}>
                    <audio className="react-drum-machine-clip" id="C" src="https://s3.amazonaws.com/freecodecamp/drums/Cev_H2.mp3"></audio>
                    C
                </div>
            </div>
            <div id="react-drum-machine-display">{displayText}</div>
        </div>
    );
};

export default DrumMachine;
